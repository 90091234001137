@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply text-sm border border-border rounded-sm px-2 py-1 focus:outline-none focus:border-focus focus:border-opacity-80 focus:ring-2 focus:ring-focus focus:ring-opacity-40 placeholder-placeholder;
  }
}

* {
  letter-spacing: -0.01em;
}
